export function asyncWrap(promise) {
  return promise.then((result) => [null, result]).catch((err) => [err]);
}

export function delay(delayInms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export function getUrl() {
  if (process.env.NODE_ENV === "production") {
    return import.meta.env.VITE_BACKEND
  } else {
    return import.meta.env.VITE_BACKEND;
  }
}